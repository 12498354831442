@import url("https://fonts.googleapis.com/css?family=Quicksand:300");

@media only screen and (max-width: 600px) {
  .logo {
    & img {
      width: 90%;
    }
  }
  .topLanguagesLinks {
    & a {
      margin: 8px;
    }
  }
}

@media only screen and (min-width: 601px) {
  .logo {
    & img {
      width: 60%;
    }
  }
  .topLanguagesLinks {
    & a {
      margin: 40px;
    }
  }
}

body {
  background-color: #fff;
  font-family: "Quicksand", sans-serif;
}

strong {
  font-weight: 400;
}

a {
  color: rgb(89, 165, 220);
  cursor: pointer;
  font-weight: 100;
  letter-spacing: -0.384px;
  line-height: 30.72px;
  text-decoration-color: rgba(89, 165, 220, 0.55);
  text-decoration-line: underline;
}

h1 {
  color: rgb(55, 63, 70);
  display: block;
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: -0.6px;
  line-height: 39px;
}

h2 {
  color: rgb(55, 63, 70);
  display: block;
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: -0.6px;
  line-height: 34px;
}

h5 {
  padding-top: 5px;
  font-size: 15px;
}

h6 {
  color: rgb(150, 150, 150);
  display: block;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1.3px;
  line-height: 20.8px;
  margin: 0;
  text-transform: uppercase;
}

p {
  color: rgb(55, 63, 70);
  display: block;
  font-size: 19.2px;
  font-weight: 100;
  letter-spacing: -0.384px;
  line-height: 30.72px;
  margin-bottom: 16px;
}

html,
body,
.pageWrapper {
  min-height: 100%;
  width: 100%;
  position: absolute;
  margin: 0;
  padding: 0;
  background-color: #fafafa;
}
#root {
  min-height: 100vh;
}
.killBottomMargin {
  margin-bottom: 0;
}
.killTopMargin {
  margin-top: 0;
}
.smallParagraph {
  font-size: 14px;
  line-height: 20px;
  & a {
    font-size: inherit;
  }
}
.pageWrapper {
  min-height: calc(100% - 80px);
  width: calc(100% - 80px);
  padding: 30px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
}

.container {
  max-width: 900px;
  margin: 0 auto 30px;

  &:last-of-type {
    margin: 0 auto;
  }
}

.headline {
  font-size: 56px;
  font-weight: 600;
  margin-top: 64px;
  & span {
    font-size: 48px;
    top: -2px;
    position: relative;
  }
}

.logo {
  & img {
    padding-bottom: 32px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.text-center {
  text-align: center;
  p {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: normal;
    line-height: 30.72px;
    margin-bottom: 16px;
  }
}
.formInput,
.formButton {
  padding: 5px 7px;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 200;
  border-radius: 4px;
}
.formLabel,
.formErrorMessage {
  font-size: 14px;
  font-weight: 200;
  display: block;
  margin: 0;
}
.formInputsContainer {
  display: flex;
}
.formInput {
  outline: 0;
  border: 1px solid #444;
  margin-bottom: 10px;
  &::placeholder {
    color: #ccc;
  }
}
.formButtons {
  display: inline-block;
}
.formButtons.cpm {
  display: flex;
  flex-direction: column;
  align-items: center;

  button:first-child {
    margin-top: 16px;
  }
}
.formButton {
  outline: none;
  border: 1px solid #347f4d;
  color: #347f4d;
  margin-left: 5px;
  background: #fff;
  transition: 0.2s all ease-in-out;

  &:hover {
    color: #fff;
    background: #347f4d;
    transition: 0.2s all ease-in-out;
  }
}
.formButton-cancel {
  border: 1px solid #aaa;
  color: #aaa;
  transition: 0.2s all ease-in-out;

  &:hover {
    background: #aaa;
    transition: 0.2s all ease-in-out;
  }
}
.formButton.cpm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 315px;
  min-height: 39px;
  margin: 6px 0;
  padding-left: 16px;
  background: #f3f3f3;
  color: #373f46;
  border-color: #e5e5e5;
  cursor: pointer;
  transition: 0.2s all ease-in-out;

  &:hover {
    background: #aaa;
    transition: 0.2s all ease-in-out;
  }

  &:focus {
    background: #aaa;
    transition: 0.2s all ease-in-out;
  }
  .buttonLeft {
    text-align: left;
    max-width: 257px;
  }
  .buttonRight {
    width: 42px;
  }
}
.formErrorMessage {
  color: #f1512f;
  width: 160px;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  text-align: left;
}

.submitErrorMessage {
  color: #f1512f;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 12px;
}
.errorFormInput {
  border-color: #f1512f;
}
.promoContent {
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  height: 600px;
  margin-bottom: 50px;
  max-width: 90%;
  overflow: visible;
  padding: 10px;
  width: 400px;
}

.loading {
  font-size: 18px;

  small {
    font-size: 11px;
    color: #666;
    letter-spacing: 0.3px;
  }
}

.topLanguagesLinks,
.smallParagraph {
  & button {
    cursor: pointer;
    letter-spacing: -0.384px;
    line-height: 30.72px;
    font-size: 18px;
    text-decoration-color: rgba(89, 165, 220, 0.55);
    text-decoration-line: underline;
    text-decoration-line: none;
    color: #387bab;
    font-weight: 400;
    border: none;
    background-color: #fff;
    margin: 40px;

    &:hover {
      text-decoration-line: underline;
    }
  }
}

.cpm-radio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;

  .radio-group {
    display: flex;
    width: 200px;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    font-size: 15px;
    line-height: 17.7px;
    font-weight: 700;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    padding: 16px 16px 16px 33px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    label {
      cursor: pointer;
      margin-left: 16px;
    }

    input {
      position: absolute;
      opacity: 0;
    }

    .checkmark {
      position: absolute;
      top: 16px;
      left: 10px;
      height: 16px;
      width: 16px;
      font-weight: 700;
      background-color: #fff;
      border: 1px solid #e6e6e6;
      border-radius: 50%;
      cursor: pointer;
    }

    input:checked ~ .checkmark {
      background-color: #187c92;
      border: 1px solid #187c92;
    }

    input:checked ~ label {
      color: #187c92;
    }

    .checkmark:after {
      top: 4px;
      left: 4px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    input:checked ~ .checkmark:after {
      display: block;
    }
  }

  .radio-group:hover {
    background-color: rgba(24, 124, 146, 0.15);
  }

  .radio-group:hover input ~ .checkmark {
    background-color: #ccc;
  }

  .radio-group:hover input:checked ~ .checkmark {
    background-color: #187c92;
  }

  .radio-group-selected {
    border-color: #187c92;
    background-color: rgba(24, 124, 146, 0.1);
  }
}

.cpmBack {
  button {
    color: #187c92;
    background: #fff;
    border: none;
    font-size: 15px;
    font-weight: 700;
    cursor: pointer;
    line-height: 17.7px;
  }
}

@media only screen and (max-width: 600px) {
  .cpm-radio {
    padding: 30px;
    .radio-group {
      width: 100%;
    }
  }

  .formBody {
    width: 300px;
  }

  .formInputsContainer {
    flex-direction: column;
  }

  .formInput {
    width: 96%;
    padding: 12px;
  }

  .formErrorMessage {
    width: 100%;
    font-size: 14px;
  }

  .formButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 106%;
  }

  .formButton {
    margin: 8px 0;
    padding: 12px;
    width: 100%;
    color: #fff;
    background: #347f4d;
  }

  .formButton-cancel {
    background: #bdbdbd;
    color: #000;
  }
}
